.wrapper {
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(69, 69, 69, 0.25);
}

.content {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    width: 600px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}