.container {
  width: 80%;
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  min-height: 500px;
  justify-content: flex-start;
  gap: 10px;
}

.breadcrumbs-wrapper {
  margin-left: 25px;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  margin-left: 25px;
}

.title {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  padding: 20px 0;
  font-size: 25px;
}

.main-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
  justify-content: center;
}

/* Table */

.table-section-wrapper {
  padding: 30px;
  overflow: overlay;
  min-height: 250px;
  border-radius: 5px;
}

#files-table {
  border-collapse: collapse;
  width: 100%;
}

#files-table thead tr {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  color: rgb(149 149 149);
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

#files-table td,
#files-table th,
#files-table tbody tr th {
  padding: 5px;
  text-align: center;
}

#files-table tbody tr th {
  font-weight: 100;
}

.row {
  height: 40px;
}

.tag-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: white;
  font-size: 11px;
  padding: 6px;
  border-radius: 9px;
  margin: 5px;
  cursor: pointer;
}

.pagination-container {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
