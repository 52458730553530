.del-icon-container {
  display: block;
  margin: auto;
  cursor: pointer;
  width: fit-content;
  font-size: 20px;
  color: brown;
  padding: 5px 10px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.del-icon-container:hover {
  background-color: #dddddd;
  border-radius: 5px;
}

.disabled-button {
  display: block;
  margin: auto;
  cursor: pointer;
  width: fit-content;
  font-size: 20px;
  color: brown;
  padding: 5px 10px;
  transition: all 0.3s ease;
  white-space: nowrap;
  cursor: not-allowed;
  opacity: 0.5;
}

@media only screen and (max-width: 500px) {
  .del-icon-container {
    font-size: 15px;
  }
}

@media screen and (max-width: 650px) {
  .del-icon-container {
    font-size: 15px;
  }
}
