.container {
    width: 100%;
}

.title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    margin-left: 25px;
}

.title {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    padding: 20px 0;
    font-size: 25px;
}


.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    border: 1px solid rgb(231, 231, 231);
    min-height: 200px;
}

.stepper-action-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}