.hapax-counts-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
}

.hapax-counts-wrapper {
  display: flex;
  gap: 50px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
