.header {
    padding: 0 5%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #142637;
    color: white;
    box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
}

.header h1 {
    color: black;
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.logo-container {
    /* width: 100%; */
    display: flex;
    gap: 12px;
    /* flex-grow: 1; */
    align-items: center;
}

.logo {
    width: 100px;
    height: 60px;
    cursor: pointer;
}

.logo-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    font-size: 35px;
    font-weight: 900;
}

.environment-info-container {
    display: flex;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #313f4d;
    text-transform: uppercase;
}

.content {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    color: black;
}

.user-info {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.user-actions {
    display: flex;
    cursor: pointer;
}

/* Dropdown menu */
.dropdown-menu {
    position: relative;
    display: inline-block;
}

.user-info {
    cursor: pointer;
    padding: 4px;
    transition: all 0.5s ease;
    border-radius: 50%;
}

.user-info:hover {
    background-color: rgb(196, 174, 90);
    transition: all 0.5s ease;
}

.dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    text-align: center;
    padding: 5px;
}


.page-menu {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.navbar-menu-item {
    color: white;
    padding: 10px;
    background: #e1c48b6b;
    font-size: 12px;
    font-weight: 900;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;

}

.navbar-menu-item:hover {
    background-color: #b7944e6b;
    transition: all .3s ease;
}

/* Little trick to have font-weight without bolder */
button {
    all: unset
}