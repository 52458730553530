.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    margin-left: 25px;
}

.title {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    padding: 20px 0;
    font-size: 25px;
}

.uploaded-file-number-information {
    background-color: #e7e7e7;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
}


.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;
    gap: 40px;
    background-color: white;
    border: 1px solid rgb(231, 231, 231);
    min-height: 250px;
    overflow: overlay;
}


.empty-table-wrapper {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    text-align: center;
    justify-content: center;
    min-height: 250px;
    gap: 15px
}

#uploaded-file-table {
    border-collapse: collapse;
    width: 100%;
}

#uploaded-file-table thead tr {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    color: rgb(149 149 149);
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

#uploaded-file-table td,
#uploaded-file-table th,
#uploaded-file-table tbody tr th {
    padding: 5px;
    text-align: center;
}

#uploaded-file-table tbody tr th {
    font-weight: 100;
}

.loading-content-wrapper {
    display: flex;
    justify-content: center;
}

.pagination-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1250px) {
    .content-wrapper {
        overflow: overlay;
    }
}