.container {
  width: 80%;
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  min-height: 500px;
  justify-content: flex-start;
  gap: 10px;
}

.breadcrumbs-wrapper {
  margin-left: 25px;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  margin-left: 25px;
}

.title {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  padding: 20px 0;
  font-size: 25px;
}

.main-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
