.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 5px;
  z-index: 1000;
  font-size: 16px;
  border-radius: 5px;
}

.clear-button:hover {
  background-color: #e0e0e0;
  transition: all 0.4s ease;
}