.stepper-container {
    display: flex;
}

.step-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 12rem;
}

.step-item:not(:first-child):before {
    content: '';
    background-color: #CBD5E0;
    position: absolute;
    width: 100%;
    height: 3px;
    right: 50%;
    top: 56.33%;
    transform: translateY(-50%);
}

.step {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    background-color: #a0a0a1;
    border-radius: 50%;
    font-weight: bold;
    color: #ffffff;
    transition: all .7s ease;
}

.active .step {
    background-color: #18324b;
}

.complete .step {
    background-color: #008000;
}

@media screen and (min-width: 200px) and (max-width: 640px) {
    .step {
        font-size: 10px;
        width: 1.5rem;
        height: 1.5rem;
    }

    .step-item {
        width: 5rem;
        font-size: 10px;
    }
}