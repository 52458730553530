.container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    margin-left: 25px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 25px;
}


.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-color: white;
    border: 1px solid rgb(231, 231, 231);
    min-height: 350px;
}

.upload-type-input-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.file-upload-section-wrapper {
    margin: 20px 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.file-upload-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stepper-action-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}