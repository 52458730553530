.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.field-container {
  display: flex;
  flex-direction: column;
}

.text-input {
  font-family: inherit;
  padding: 0px 12px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(206 215 226);
}

.text-input:focus {
  outline: none;
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.text-input::placeholder {
  color: "#868a8d";
}

.label {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 2;
}

/* Sensitive columns alert */

.alert-message-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  font-style: italic;
}

.alert-icon-wrapper {
  font-size: 15px;
  color: rgb(199, 133, 27);
}

/* Advanced options section */

.advanced-options-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 10px;
  overflow: overlay;
}

.advanced-options-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkbox-inputs-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  column-gap: 30px;
}

.checkbox-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
