.file-uploader-container {
    gap: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.file-upload-form {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
}

.upload-information-wrapper {
    display: flex;
    height: 250px;
    flex-direction: column;
    gap: 20px;
    transition: all 0.9s ease;
    color: #424746;
    border: 1px dashed #6d6d6d;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.upload-file-icon {
    font-size: 50px;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
}

#input-file {
    display: none;
}

.extensions-information-wrapper {
    text-align: center;
    font-style: italic;
    font-size: 12px;
    display: flex;
    color: #424746;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.selected-files-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 5px;
}

.gcs-uploader-toggle-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.footer {
    display: flex;
    justify-content: center;
    margin: 20px
}