.container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.gcp-project-name-container,
.gcp-dataset-uri-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
}

.label {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 2;
}

.gcp-project-name-container input[type="text"],
.gcp-dataset-uri-container input[type="text"] {
  font-family: inherit;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(206 215 226);
}

.gcp-project-name-container input[type="text"]:focus,
.gcp-dataset-uri-container input[type="text"]:focus {
  outline: none !important;
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}