.ngram-frequencies-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
}

.ngram-blocks-wrapper {
  display: flex;
  gap: 50px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.ngram-block {
  display: flex;
  border: 1px solid #f0f0f0;
  flex-direction: column;
  padding: 20px;
  width: 40%;
  gap: 15px;
  border-radius: 10px;
  box-shadow: 0 0px 7px rgba(0, 0, 0, 0.1);
}

.ngram-block-data {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.ngram-block-title {
  text-align: center;
  font-weight: 900;
}

/* Ngram item */

.ngram-item-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}

.ngram-item {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ngram-item-title {
  width: 100%;
  text-align: right;
}

.ngram-item-frequency {
  width: 100%;
}

.ngram-item-frequency-value {
  width: 30%;
}
