.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.field-container {
  display: flex;
  flex-direction: column;
}

.text-input {
  font-family: inherit;
  padding: 0px 12px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(206 215 226);
}

.custom-textarea {
  font-family: inherit;
  padding: 12px;
  height: 100px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(206 215 226);
  resize: vertical;
  max-height: 400px;
}

.text-input:focus {
  outline: none;
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.text-input::placeholder {
  color: "#868a8d";
}

.label {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 2;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
