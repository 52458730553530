.container {
    display: flex;
    width: 80%;
    margin: 20px 0;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.breadcrumbs-wrapper {
    margin-left: 25px;
}

.title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    margin-left: 25px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}


.form-container {
    width: 100%;
}

.title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    margin-left: 25px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 25px;
}

.form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: white;
    border: 1px solid rgb(231, 231, 231);
    min-height: 350px;
    gap: 20px;
}


.content {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input-label-wrapper input::placeholder {
    color: "#868a8d"
}


.stepper-action-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
}