.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  margin-left: 25px;
}

.title {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  padding: 20px 0;
  font-size: 25px;
}

.uploaded-file-number-information {
  background-color: #e7e7e7;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
}

.content-wrapper-without-style {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 15px;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
  min-height: 200px;
  max-height: 300px;
  overflow: overlay;
}

.file-information-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.file-type {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  font-size: 20px;
  height: 40px;
  background-color: #e7e7e7;
  border-radius: 5px;
}

.filename {
  flex: 1;
}

.actions-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.empty-table-wrapper {
  display: flex;
  flex-direction: column;
  font-family: inherit;
  text-align: center;
  justify-content: center;
  min-height: 250px;
  gap: 15px;
}