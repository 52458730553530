.topic-detection-analysis-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
}

.topic-blocks-wrapper {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.topic-block {
  padding: 20px 10px;
  text-transform: uppercase;
  background: #c9dcea6b;
  border-radius: 5px;
  font-weight: 900;
}
