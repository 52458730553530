.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 10px;
}

.file-type-wrapper {
    font-size: 28px;
}

.file-information-grid {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.file-information-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.progress-percentage {
    font-weight: 500;
}

.progress-bar-wrapper {
    display: block;
    width: 100%;
}

.files-uploaded {
    font-size: 20px;
    padding: 8px;
    color: rgb(9, 77, 7);
}

.file-canceled {
    font-size: 20px;
    padding: 8px;
    color: rgb(112, 11, 11);
}