.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.field-container {
  display: flex;
  flex-direction: column;
}

.text-input {
  font-family: inherit;
  padding: 0px 12px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(206 215 226);
}

.text-input:focus {
  outline: none;
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.text-input::placeholder {
  color: "#868a8d";
}

.label {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 2;
}

/* Preprocess config section */

.project-preprocess-config-wrapper {
  align-content: center;
  -webkit-column-gap: 25px;
  column-gap: 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 270px;
  row-gap: 10px;
}

.toggle-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 11px;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 11px;
}

/* Popup description section */

.popup-description {
  background: #f7f7f7;
  display: none;
  width: 280px;
  top: 25px;
  z-index: 1;
  position: absolute;
  align-items: center;
  justify-content: center;
  border: 0.1px solid #cccccc;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 4px 2px 20px rgb(0 0 0 / 0.2);
}

.description-icon {
  cursor: pointer;
  font-size: 20px;
  color: #7c949c;
}

/* Switch button section */

.switch {
  display: inline-block;
  position: relative;
  width: 47px;
  height: 24px;
  cursor: pointer;
  overflow: hidden;
}

.switch input {
  position: absolute;
  top: -30px;
  top: -30px;
  width: 0;
  height: 0;
}

.switch input+span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #a2a2a3;
  border-radius: 20px;
}

.switch input+span:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 3px;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.switch input:checked+span {
  background: #208d12;
}

.switch input:checked+span:before {
  left: 25px;
}