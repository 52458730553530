.main-block {
  text-align: center;
}

.main-block-header {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  color: #606060;
  align-items: center;
  margin-left: 30px;
  height: 50px;
  font-weight: 900;
  text-transform: uppercase;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.title-block-number-information {
  background-color: #e7e7e7;
  padding: 5px;
  border-radius: 5px;
}

.title-actions-wrapper {
  display: flex;
}

.main-block-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.block-name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.block-name-circle {
  color: #b5a43963;
  font-size: 6px;
}

.main-block-add-new-block-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
}

.block-box-wrapper {
  padding: 7px 10px;
  display: flex;
  margin: 0 15px;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 4px;
  cursor: pointer;
}

.block-box-wrapper:hover {
  background-color: #f1f0ea;
  transition: transform 0.7s ease;
}

.draggable-block-box-wrapper {
  padding: 7px 10px;
  display: flex;
  margin: 0 15px;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  cursor: move;
  border: 1px solid #e7e7e7;
  transition: transform 0.2s ease;
}

.dragging {
  opacity: 0;
  transition: all 0.2s ease;
}

/* Custom confirmation modal */

.order-changes-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.confirmation-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-actions-buttons-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
}

.play-button-wrapper {
  display: flex;
}

.play-button {
  color: rgb(46 116 36);
  background: #e7e7e7;
  padding: 5px 7px;
  border-radius: 50%;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.play-button:hover {
  background-color: #adacac;
  transition: all 0.5s ease;
}
