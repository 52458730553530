.transparent-button {
  display: flex;
  font-family: inherit;
  gap: 5px;
  font-size: 13.5px;
  padding: 10px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #ced7e2;
  cursor: pointer;
  transition: all 0.4s ease;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.transparent-button:hover {
  background-color: #f0f4f8;
}

.disabled-button {
  display: flex;
  font-family: inherit;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  font-size: 13.5px;
  padding: 10px;
  background-color: #bcb7b7cc;
  border-radius: 5px;
  font-weight: 900;
  color: rgb(0, 0, 0);
  opacity: 0.5;
  border: none;
  cursor: no-drop !important;
  white-space: nowrap;
}
