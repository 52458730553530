.container {
  width: 90%;
  display: flex;
  gap: 50px;
  flex-direction: column;
}

/* Header */

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  margin-left: 25px;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 0;
  font-size: 25px;
}

.subtitle {
  font-size: 12px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.actions-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

/* Body */

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.row-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
}

.block-type-title {
  text-transform: uppercase;
  background: #f9f9f9;
  margin: -10px 20px;
  display: inline-block;
  position: absolute;
  font-weight: 900;
}

.row-container:before {
  content: "";
  background-color: #e5e5e5;
  position: absolute;
  top: 100%;
  right: 50%;
  width: 2px;
  height: 72%;
}

.block-name {
  flex-basis: 25%;
}

.block-type-tag {
  flex-basis: 25%;
}

.block {
  font-size: 12px;
  padding: 5px 8px;
  background: #26a72954;
  border-radius: 10px;
}

.gray-tag {
  background-color: #e0e0e0;
  font-weight: 600;
}

.green-tag {
  background-color: #447e44;
  color: white;
  font-weight: 600;
}

.orange-tag {
  background-color: #a39946;
  color: white;
  font-weight: 600;
}

.label-wrapper {
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 10px;
  flex-basis: fit-content;
  text-transform: uppercase;
}

/* Custom edit button */

.modify-enabled-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

/* Delete button */

.no-border {
  border: unset !important;
}

.delete-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 9px 12px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #e4b3b3;
  cursor: pointer;
  transition: all 0.4s ease;
}

.delete-button:hover {
  background-color: #ffe7e7;
}

/* Add new block button container */

.new-block-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-new-block-custom-button {
  font-family: inherit;
  font-size: 20px;
  padding: 10px 5px;
  border: unset;
  background: none;
  border-radius: 5px;
  font-weight: 900;
  color: rgb(146, 136, 136);
  cursor: pointer;
  transition: all 0.4s ease;
}

.add-new-block-custom-button:hover {
  color: #142637;
}

/* No blocks error container */

.no-blocks-error-container {
  display: flex;
  justify-content: center;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
}

.transparent-button {
  display: flex;
  font-family: inherit;
  gap: 5px;
  font-size: 13.5px;
  padding: 10px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #ced7e2;
  cursor: pointer;
  transition: all 0.4s ease;
  flex-direction: row;
  align-items: center;
}

.transparent-button:hover {
  background-color: #f0f4f8;
}
