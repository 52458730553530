.container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 25px;
}

.wordcloud-img-container {
  display: flex;
  gap: 50px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.download-button-container {
  display: flex;
  justify-content: center;
}
