.global-stats-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
}

.global-stats-wrapper {
  display: flex;
  gap: 50px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.stats-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
}

.stat-card {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.stat-values {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.stat-value {
  display: flex;
  flex-direction: column;
  background-color: #e9ecef;
  border-radius: 5px;
  padding: 10px;
  flex: 1;
  min-width: 100px; /* Taille minimale pour chaque valeur */
}

.stat-key {
  font-weight: bold;
  color: #333;
}

.stat-value-number {
  color: #007bff; /* Couleur pour les valeurs */
}
