.button-with-icon {
  font-family: inherit;
  font-size: 20px;
  padding: 5px 10px;
  background: none;
  border-radius: 5px;
  font-weight: 900;
  color: rgb(47, 47, 47);
  cursor: pointer;
  border: none;
  transition: all 0.4s ease;
}

.button-with-icon:hover {
  background-color: #e0e0e0;
  transition: all 0.4s ease;
}

.disabled-button {
  display: block;
  margin: auto;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  font-weight: bold;
  font-size: 20px;
  color: #3b3a3a;
  padding: 5px 10px;
  transition: all 0.3s ease;
  cursor: not-allowed;
  opacity: 0.5;
}
