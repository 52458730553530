.primary-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 10px;
  background-color: #142637cc;
  border-radius: 5px;
  font-weight: 900;
  color: white;
  cursor: pointer;
  border: none;
  transition: all 0.4s ease;
  white-space: nowrap;
}

.primary-button:hover {
  background-color: #0c2033;
  transition: all 0.4s ease;
}

.disabled-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 10px;
  background-color: #bcb7b7cc;
  border-radius: 5px;
  font-weight: 900;
  color: rgb(0, 0, 0);
  opacity: 0.5;
  border: none;
  cursor: no-drop !important;
  white-space: nowrap;
}

@media screen and (max-width: 550px) {
  .primary-button {
    font-size: 10px;
  }
}
