:root {
  --button-with-icon-text-color: rgb(47, 47, 47);
  --button-with-icon-hover-color: #e0e0e0
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(249 249 249);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

main {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: row;
}