.container {
  width: 90%;
  display: flex;
  gap: 40px;
  flex-direction: column;
}

/* Header */

.title-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  margin-left: 25px;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 0;
  font-size: 25px;
}

.subtitle {
  font-size: 12px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.actions-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

/* Body */

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.block-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.block-informations {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.block-type-tag {
  flex-basis: 25%;
}

.block {
  font-size: 12px;
  padding: 5px 8px;
  background: #26a72954;
  border-radius: 10px;
}

.block-actions-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
  flex: 1;
}

.gray-tag {
  background-color: #e0e0e0;
  font-weight: 600;
}

.pink-tag {
  background-color: #a44f68;
  color: white;
  font-weight: 600;
}

.green-tag {
  background-color: #447e44;
  color: white;
  font-weight: 600;
}

.orange-tag {
  background-color: #a39946;
  color: white;
  font-weight: 600;
}

.label-wrapper {
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 10px;
  flex-basis: fit-content;
  text-transform: uppercase;
  white-space: nowrap;
}

/* Custom new block button */

.new-block-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 10px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #ced7e2;
  cursor: pointer;
  transition: all 0.4s ease;
}

.new-block-button:hover {
  background-color: #f0f4f8;
}

/* Custom edit button */

.edit-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 10px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #ced7e2;
  cursor: pointer;
  transition: all 0.4s ease;
  white-space: nowrap;
}

.edit-button:hover {
  background-color: #f0f4f8;
}

.modify-enabled-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

/* Custom cost estimate button */

.cost-estimate-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 10px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #ced7e2;
  cursor: pointer;
  transition: all 0.4s ease;
}

.cost-estimate-button:hover {
  background-color: #f0f4f8;
}

/* Custom run button */

.run-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 10px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #ced7e2;
  cursor: pointer;
  transition: all 0.4s ease;
}

.run-button:hover {
  background-color: #f0f4f8;
}

/* Delete button */

.no-border {
  border: unset !important;
}

.delete-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 9px 12px;
  background-color: unset;
  border-radius: 5px;
  font-weight: 900;
  color: #191919;
  border: 1px solid #e4b3b3;
  cursor: pointer;
  transition: all 0.4s ease;
}

.delete-button:hover {
  background-color: #ffe7e7;
}

/* No blocks error container */

.no-blocks-error-container {
  display: flex;
  justify-content: center;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
}
