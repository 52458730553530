.container {
    width: 80%;
    display: flex;
    margin: 20px 0;
    flex-direction: column;
    min-height: 500px;
    justify-content: flex-start;
    gap: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 30px;
    font-weight: 900;
}

.actions-buttons-wrapper {
    display: flex;
    gap: 8px;
}

.welcome-page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border: 1px solid rgb(231, 231, 231);
    height: 500px;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}