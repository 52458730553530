.row-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgb(231, 231, 231);

}

.block-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 30px;
}

.block-type-title {
    text-transform: uppercase;
    background: #f9f9f9;
    margin: -10px 20px;
    display: inline-block;
    position: absolute;
    font-weight: 900;
}

.row-container:before {
    content: '';
    background-color: #e5e5e5;
    position: absolute;
    top: 100%;
    right: 50%;
    width: 2px;
    height: 72%;
}

.block-informations {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
    align-items: center;
}

.block-name {
    /* flex-basis: 35%; */
}

.block-type-tag {
    flex-basis: 25%;
}

.block {
    font-size: 12px;
    padding: 5px 8px;
    background: #26a72954;
    border-radius: 10px;
}

.block-actions-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-end;
    flex: 1;
}

.gray-tag {
    background-color: #e0e0e0;
    font-weight: 600;
}

.green-tag {
    background-color: #447e44;
    color: white;
    font-weight: 600;
}

.orange-tag {
    background-color: #a39946;
    color: white;
    font-weight: 600;
}

.label-wrapper {
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 10px;
    flex-basis: fit-content;
    text-transform: uppercase;
}

.fail-icon-style {
    color: #a00000;
    font-size: 22px;
}

.success-icon-style {
    color: #0b6a0a;
    font-size: 22px;
}

/* Custom edit button */

.edit-button {
    display: flex;
    font-family: inherit;
    gap: 5px;
    font-size: 13.5px;
    padding: 10px;
    background-color: unset;
    border-radius: 5px;
    font-weight: 900;
    color: #191919;
    border: 1px solid #ced7e2;
    cursor: pointer;
    transition: all 0.4s ease;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
}

.edit-button:hover {
    background-color: #f0f4f8;
}

.modify-enabled-buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

/* Custom cost estimate button */

.cost-estimate-button {
    font-family: inherit;
    font-size: 13.5px;
    padding: 10px;
    background-color: unset;
    border-radius: 5px;
    font-weight: 900;
    color: #191919;
    border: 1px solid #ced7e2;
    cursor: pointer;
    transition: all 0.4s ease;
}

.cost-estimate-button:hover {
    background-color: #f0f4f8;
}

/* Custom run button */

.run-button {
    font-family: inherit;
    font-size: 13.5px;
    padding: 10px;
    background-color: unset;
    border-radius: 5px;
    font-weight: 900;
    color: #191919;
    border: 1px solid #ced7e2;
    cursor: pointer;
    transition: all 0.4s ease;
}

.run-button:hover {
    background-color: #f0f4f8;
}

/* Delete button */

.no-border {
    border: unset !important;
}

.delete-button {
    font-family: inherit;
    font-size: 13.5px;
    padding: 9px 12px;
    ;
    background-color: unset;
    border-radius: 5px;
    font-weight: 900;
    color: #191919;
    border: 1px solid #e4b3b3;
    cursor: pointer;
    transition: all 0.4s ease;
}

.delete-button:hover {
    background-color: #ffe7e7;
}

.estimated-cost-information {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-size: 13.5px;
    padding: 10px;
    background-color: unset;
    border-radius: 5px;
    font-weight: 900;
    color: #191919;
    border: 1px solid #ced7e2;
}

.validated-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
    font-family: inherit;
    font-size: 13.5px;
    padding: 10px;
    background-color: unset;
    border-radius: 5px;
    font-weight: 900;
    color: #191919;
    border: 1px solid #ced7e2;
}