.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  font-family: "Arial", sans-serif;
}

.login-wrapper {
  display: flex;
  background-color: #ffffff;
  border: 1px solid rgb(231, 231, 231);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  border-radius: 20px;
  color: black;
  width: 400px;
  padding: 40px;
  text-align: center;
}

.logo-wrapper {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.welcome-text {
  margin-bottom: 20px;
}

.greetings-text {
  font-size: 32px;
  margin: 0;
}

.description {
  font-size: 16px;
  color: #555;
}

.button-wrapper {
  margin-top: 20px;
}

.login-button {
  color: white;
  font-size: 16px;
  border: none;
  outline: none;
  background: #007bff;
  padding: 15px 25px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.login-button:hover {
  background: #0056b3;
  transform: scale(1.05);
}
