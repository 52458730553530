.container {
    display: flex;
    width: 100%;
}

.sidebar {
    position: relative;
    height: 100%;
    border-right: 1px solid rgb(231, 231, 231);
    width: 25%;
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dynamic-content-container {
    width: 100%;
}

.config-workflow-container {
    margin: 0 50px;
}