.container {
    display: flex;
    width: 80%;
    margin: 20px 0;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.breadcrumbs-wrapper {
    margin-left: 25px;
}

.title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    margin-left: 25px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.stepper-wrapper {
    display: flex;
    justify-content: center;
}