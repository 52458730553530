.container {
  width: 100%;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  margin-left: 25px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-size: 25px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
  min-height: 350px;
  align-items: center;
}

.content {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 2;
}

.dataset-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dataset-input-wrapper input[type="text"] {
  font-family: inherit;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(206 215 226);
}

.dataset-input-wrapper input[type="text"]:focus {
  outline: none !important;
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.stepper-action-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}
