.order-changes-modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.confirmation-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-actions-buttons-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
}