.custom-menu-button {
  display: flex;
  font-family: inherit;
  font-size: 20px;
  padding: 10px 5px;
  border: unset;
  background: none;
  border-radius: 5px;
  font-weight: 900;
  color: rgb(146, 136, 136);
  cursor: pointer;
  transition: all 0.4s ease;
}

.custom-menu-button:hover {
  color: #142637;
}

.disabled-button {
  font-family: inherit;
  font-size: 13.5px;
  padding: 10px;
  background-color: #bcb7b7cc;
  border-radius: 5px;
  font-weight: 900;
  color: rgb(0, 0, 0);
  opacity: 0.5;
  border: none;
  cursor: no-drop !important;
}
