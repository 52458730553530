.container {
  width: 100%;
  overflow: overlay;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  margin-left: 25px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  gap: 10px;
  font-size: 25px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
  min-height: 350px;
  overflow: overlay;
}

.content {
  width: 100%;
}

.empty-table-wrapper {
  display: flex;
  flex-direction: column;
  font-family: inherit;
  text-align: center;
  justify-content: center;
  min-height: 250px;
  gap: 15px;
}

.id-column {
  width: 80px;
}
